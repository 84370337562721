import 'helpers/bugsnag';

console.info('Version', process.env.VERSION);

function onLoad() {
    Promise.all([
        import(/* webpackChunkName: "pages/index" */'./Pages/Index'),
        import(/* webpackChunkName: "helpers/intercom" */'helpers/intercom'),
        import(/* webpackChunkName: "helpers/i18n" */'helpers/i18n'),
    ])
        .then(([{ default: loadIndex }, { loadIntercom }]) => {
            loadIntercom();
            loadIndex();
        });
}

// Use the window load event to keep the page load performant
window.addEventListener('load', onLoad);

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function fixIosBug() {
    const vw = window.innerWidth * 0.01;
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let layoutTimeoutId;

function reLayout() {
    clearTimeout(layoutTimeoutId);

    setTimeout(fixIosBug, 100);
}

window.addEventListener('resize', reLayout);
window.addEventListener('orientationchange', reLayout);

fixIosBug();

// Debug page
document.addEventListener('keypress', function onEvent(event) {
    if (event.ctrlKey && (event.key === 'k' || event.key === 'i')) {
        import(/* webpackChunkName: "pages/debug" */'./Pages/Debug')
            .then(({ default: loadDebug }) => {
            loadDebug();
        });
    }
});
